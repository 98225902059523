(function ($) {
    $(function () {
        const ano = (new Date).getFullYear();
        $("#anno").text(ano);

        $('.sidenav').sidenav();
        $('.parallax').parallax();
        $('.slider').slider({
            height: 500,
            indicators: true,

        });


        // Masonry
        /*$('.grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: 300,
            isFitWidth: false,
            gutter: 10,
            percentPosition: true
        });
        */
    }); // end of document ready
})(jQuery); // end of jQuery name space

// init Masonry
const $grid = $('.grid').masonry({
    itemSelector: '.grid-item',
    percentPosition: true,
    columnWidth: '.grid-sizer',
    isHorizontal: false,
});
// layout Masonry after each image loads
$grid.imagesLoaded().progress(function () {
    $grid.masonry();
});


// 645294424.8d8702b.3413c0c9e6f54910b6d53b33fd38a4b2